<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-card class="pa-5">
          <v-card-title>
            <span class="headline">
              {{ $vuetify.lang.t("$vuetify.processConversionReport.title") }}
            </span>
            <v-spacer></v-spacer>
            <!-- Toggle button to show/hide parameters -->
            <v-btn icon @click="toggleParameters">
              <v-icon>{{ showParameters ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-title>

          <!-- Parameters Panel wrapped in an expand transition -->
          <v-expand-transition>
            <div v-if="showParameters">
              <conversion-report-parameters
                :processTypes="processTypes"
                :linkedProcessTypes="linkedProcessTypes"
                :inputProducts="inputProducts"
                :outputProducts="outputProducts"
                :isInputProductsLoading="isInputProductsLoading"
                :isOutputProductsLoading="isOutputProductsLoading"
                @parametersSelected="handleParametersSelected"
              ></conversion-report-parameters>
              <v-divider class="my-5"></v-divider>
            </div>
          </v-expand-transition>

          <!-- Tabs to switch between Aggregated Report and Raw Data -->
          <v-tabs v-model="selectedTab" background-color="transparent" grow>
            <v-tab key="aggregated">
              Aggregated Report
            </v-tab>
            <v-tab key="raw">
              Raw Data
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>

          <v-card-text>
            <!-- Aggregated Report Tab -->
            <div v-if="selectedTab === 0">
              <!-- Loading & Error States -->
              <div v-if="loadingReport">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </div>
              <div v-else-if="!reportData || (!reportData.inputInventories.length && !reportData.outputInventories.length)">
                <v-alert type="info">
                  {{ $vuetify.lang.t("$vuetify.processConversionReport.noReport") }}
                </v-alert>
              </div>
              <div v-else>
                <!-- Filter, Column Selection and Export Buttons Row -->
                <v-row class="mb-3" align="center">
                  <v-col cols="12" class="d-flex justify-end">
                    <!-- Open Threshold Filter Dialog -->
                    <v-btn icon @click="reportFilter.dialog = true">
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                    <!-- Open Column Selection Dialog -->
                    <v-btn icon @click="columnFilter.dialog = true">
                      <v-icon>mdi-table-column</v-icon>
                    </v-btn>
                    <v-btn color="primary" @click="exportAggregatedReport">
                      Export Aggregated Report to Excel
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- Final Aggregated Report Table using filtered data -->
                <v-data-table
                  :headers="finalReportHeaders"
                  :items="filteredFinalReportItems"
                  class="elevation-1"
                  dense
                  :items-per-page="10"
                  :footer-props="{
                    'items-per-page-options': [10, 15, 20],
                    'items-per-page-text': $vuetify.lang.t('$vuetify.dataTable.itemsPerPageText'),
                    'show-current-page': true
                  }"
                >
                  <!-- Custom item slot for formatting cells -->
                  <template v-slot:item="{ item, headers }">
                    <tr>
                      <td v-for="header in headers" :key="header.value">
                        <!-- If column is "processCodes", display process codes/time + subgraph button -->
                        <span v-if="header.value === 'processCodes'">
                          <div v-for="proc in item.processCodes" :key="proc.id">
                            {{ proc.time | formatDateTime }} -
                            <a
                              target="_blank"
                              :href="`https://prod.${domain}.com/factory/process/${proc.id}`"
                            >
                              {{ proc.code ? proc.code : 'N/A' }}
                            </a>
                            (ID: {{ proc.id }})
                          </div>
                          <!-- Subgraph details button (only if this item has a chain ID) -->
                          <div>
                            <v-btn
                              small
                              icon
                              color="primary"
                              v-if="item.chain"
                              @click="openSubgraphDialog(item.chain)"
                            >
                              <v-icon>mdi-graph</v-icon>
                            </v-btn>
                          </div>
                        </span>
                        <span v-else-if="header.value === 'processStartTime'">
                          {{ item[header.value] | formatDateTime }}
                        </span>
                        <span v-else>
                          {{ item[header.value] | formatNumber }}
                        </span>
                      </td>
                    </tr>
                  </template>
                  <!-- Footer rows for totals -->
                  <template v-slot:body.append>
                    <!-- Displayed Total Row (only if any filter is active) -->
                    <tr class="font-weight-bold" v-if="isFilterActive">
                      <td v-for="(header, index) in finalReportHeaders" :key="index">
                        <span v-if="header.value === 'processCodes'">Filtered Total</span>
                        <span v-else>
                          {{ displayedTotalRow[header.value] | formatNumber }}
                        </span>
                      </td>
                    </tr>
                    <!-- Grand Total Row -->
                    <tr class="font-weight-bold">
                      <td v-for="(header, index) in finalReportHeaders" :key="index">
                        <span v-if="header.value === 'processCodes'">Grand Total</span>
                        <span v-else>
                          {{ totalRow[header.value] | formatNumber }}
                        </span>
                      </td>
                    </tr>
                  </template>
                </v-data-table>

                <!-- Conversion Analysis Section -->
                <conversion-analysis
                  class="mt-5"
                  :aggregated-data="filteredFinalReportItems"
                  :raw-data="combinedRawInventories"
                  :overall-aggregated-data="finalReportItems"
                  :overall-raw-data="combinedRawInventories"
                ></conversion-analysis>

              </div>
            </div>

            <!-- Raw Data Tab (Combined Inventories) -->
            <div v-else-if="selectedTab === 1">
              <v-row class="mb-3">
                <v-col cols="12">
                  <v-btn color="primary" @click="exportRawData">
                    Export Raw Data to Excel
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="rawCombinedHeaders"
                :items="combinedRawInventories"
                class="elevation-1"
                dense
                :items-per-page="10"
              >
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Threshold Filter Dialog for Aggregated Report -->
    <v-dialog v-model="reportFilter.dialog" max-width="400">
      <v-card>
        <v-card-title>
          <span class="headline">Filter Aggregated Report</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <!-- Input Products Filter with Thresholds -->
            <v-subheader>Input Product Thresholds</v-subheader>
            <v-select
              v-model="reportFilter.selectedInputProductIds"
              :items="flatInputProducts"
              item-text="name"
              item-value="id"
              label="Select Input Products"
              multiple
              clearable
            ></v-select>
            <div
              v-for="id in reportFilter.selectedInputProductIds"
              :key="id"
              class="mt-2"
            >
              <v-text-field
                v-model.number="reportFilter.inputThresholds[id]"
                :label="`Threshold for ${getInputProductName(id)}`"
                type="number"
                min="0"
              ></v-text-field>
            </div>
            <!-- Output Products Filter with Thresholds -->
            <v-subheader class="mt-4">Output Product Thresholds</v-subheader>
            <v-select
              v-model="reportFilter.selectedOutputProductIds"
              :items="flatOutputProducts"
              item-text="name"
              item-value="id"
              label="Select Output Products"
              multiple
              clearable
            ></v-select>
            <div
              v-for="id in reportFilter.selectedOutputProductIds"
              :key="id"
              class="mt-2"
            >
              <v-text-field
                v-model.number="reportFilter.outputThresholds[id]"
                :label="`Threshold for ${getOutputProductName(id)}`"
                type="number"
                min="0"
              ></v-text-field>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetFilters">Reset</v-btn>
          <v-btn color="primary" text @click="reportFilter.dialog = false">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Column Selection Dialog for Aggregated Report -->
    <v-dialog v-model="columnFilter.dialog" max-width="400">
      <v-card>
        <v-card-title>
          <span class="headline">Select Displayed Columns</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-subheader>Input Product Columns</v-subheader>
            <v-select
              v-model="columnFilter.selectedInputColumns"
              :items="flatInputProducts"
              item-text="name"
              item-value="id"
              label="Select Input Columns"
              multiple
              clearable
            ></v-select>
            <v-subheader class="mt-4">Output Product Columns</v-subheader>
            <v-select
              v-model="columnFilter.selectedOutputColumns"
              :items="flatOutputProducts"
              item-text="name"
              item-value="id"
              label="Select Output Columns"
              multiple
              clearable
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetColumnSelection">Reset</v-btn>
          <v-btn color="primary" text @click="columnFilter.dialog = false">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Subgraph Details Dialog -->
    <v-dialog v-model="subgraphDialog.visible" max-width="700" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            Subgraph Details - Chain {{ subgraphDialog.chainId }}
          </span>
          <v-spacer />
          <v-btn icon @click="closeSubgraphDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div v-if="subgraphDialog.subgraph">
            <!-- Show visited processes with their codes -->
            <p><strong>Visited Processes:</strong></p>
            <div
              v-for="pid in subgraphDialog.subgraph.visitedProcessIds"
              :key="pid"
              class="ml-4"
            >
              Process ID: <strong>{{ pid }}</strong> |
              Code:
              <strong>
                {{ processMap[pid] && processMap[pid].processCode ? processMap[pid].processCode : 'N/A' }}
              </strong>
            </div>

            <!-- Show edges: source, target, linking inventories -->
            <div
              v-for="(edge, edgeIndex) in subgraphDialog.subgraph.edges"
              :key="edgeIndex"
              class="my-4"
            >
              <div><strong>Edge #{{ edgeIndex + 1 }}</strong></div>
              <div class="ml-4">
                Source: 
                <strong>{{ edge.sourceId }}</strong> 
                ({{ processMap[edge.sourceId] && processMap[edge.sourceId].processCode ? processMap[edge.sourceId].processCode : 'N/A' }})
              </div>
              <div class="ml-4">
                Target: 
                <strong>{{ edge.targetId }}</strong> 
                ({{ processMap[edge.targetId] && processMap[edge.targetId].processCode ? processMap[edge.targetId].processCode : 'N/A' }})
              </div>
              <div class="ml-4 mt-2">
                <strong>Linking Inventories:</strong>
                <div
                  v-for="invId in edge.linkingInventoryIds"
                  :key="invId"
                  class="ml-4"
                >
                  - Inventory ID: <strong>{{ invId }}</strong>,
                    Product:
                    <strong>
                      {{
                        inventoryMap[invId] && inventoryMap[invId].productName
                          ? inventoryMap[invId].productName
                          : 'N/A'
                      }}
                    </strong>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="closeSubgraphDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ConversionReportParameters from "@/components/process/ConversionReportParameters.vue";
import ConversionAnalysis from "@/components/process/ConversionAnalysis.vue";
import { parseDomain } from "parse-domain";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default {
  name: "ProcessConversionReport",
  components: {
    ConversionReportParameters,
    ConversionAnalysis,
  },
  data() {
    return {
      domain: "",
      loadingReport: false,
      reportData: null,
      // Subgraphs returned from the server
      subgraphs: [],
      reportParams: {
        startDate: this.getFirstDayOfCurrentMonth(),
        endDate: this.getLastDayOfCurrentMonth(),
        inputProductIds: [],
        outputProductIds: [],
        processIds: [],
        processTypeIds: [],
      },
      showParameters: true,
      selectedTab: 0,
      // Filter settings for Aggregated Report (threshold filtering)
      reportFilter: {
        dialog: false,
        selectedInputProductIds: [],
        inputThresholds: {},
        selectedOutputProductIds: [],
        outputThresholds: {},
      },
      // Column selection filter for controlling displayed columns
      columnFilter: {
        dialog: false,
        selectedInputColumns: [],
        selectedOutputColumns: [],
      },
      // Subgraph dialog data
      subgraphDialog: {
        visible: false,
        chainId: null,
        subgraph: null
      }
    };
  },
  mounted() {
    // Extract domain for building links (if needed)
    const parseResult = parseDomain(window.location.hostname);
    const { domain } = parseResult;
    this.domain = domain;

    // Load initial data
    this.loadProcessTypes();
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "currentUser", "userRoles"]),
    ...mapGetters("processes", [
      "processTypes",
      "linkedProcessTypes",
      "inputProducts",
      "outputProducts",
    ]),

    // Build a quick map of processId -> processCode from combined inventories
    processMap() {
      const map = {};
      this.combinedRawInventories.forEach(inv => {
        // If we haven't recorded this processId yet, store the code
        if (!map[inv.processId]) {
          map[inv.processId] = {
            processCode: inv.processCode,
          };
        }
      });
      return map;
    },
    // Build a quick map of inventoryId -> productName from combined inventories
    inventoryMap() {
      const map = {};
      this.combinedRawInventories.forEach(inv => {
        if (!map[inv.inventoryId]) {
          map[inv.inventoryId] = {
            productName: inv.inventoryName
          };
        }
      });
      return map;
    },

    flatInputProducts() {
      return this.inputProducts.flatMap(group =>
        group.products.map(prod => ({ id: prod.id, name: prod.name }))
      );
    },
    flatOutputProducts() {
      return this.outputProducts.flatMap(group =>
        group.products.map(prod => ({ id: prod.id, name: prod.name }))
      );
    },
    finalReportHeaders() {
      const headers = [];
      headers.push({
        text:
          this.$vuetify.lang.t("$vuetify.processConversionReport.processChain") ||
          "Process Chain",
        value: "processCodes",
      });
      // For input product columns
      this.inputProducts.forEach((group) => {
        group.products.forEach((prod) => {
          if (
            this.columnFilter.selectedInputColumns.length === 0 ||
            this.columnFilter.selectedInputColumns.includes(prod.id)
          ) {
            headers.push({
              text: prod.name,
              value: `input_${prod.id}`,
            });
          }
        });
      });
      headers.push({
        text: this.$vuetify.lang.t("$vuetify.summary.inputTotal") || "Total Input",
        value: "totalInput",
      });
      // For output product columns
      this.outputProducts.forEach((group) => {
        group.products.forEach((prod) => {
          if (
            this.columnFilter.selectedOutputColumns.length === 0 ||
            this.columnFilter.selectedOutputColumns.includes(prod.id)
          ) {
            headers.push({
              text: prod.name,
              value: `output_${prod.id}`,
            });
          }
        });
      });
      headers.push({
        text: this.$vuetify.lang.t("$vuetify.summary.outputTotal") || "Total Output",
        value: "totalOutput",
      });
      headers.push({
        text: this.$vuetify.lang.t("$vuetify.summary.delta") || "Difference",
        value: "difference",
      });
      return headers;
    },
    finalReportItems() {
      if (!this.reportData) return [];
      const processChainMap = {};

      // Process input inventories
      this.reportData.inputInventories.forEach((inv) => {
        const chainKey = inv.processChain ? inv.processChain : inv.processId;
        if (!processChainMap[chainKey]) {
          processChainMap[chainKey] = {
            processCodes: [{
              code: inv.processCode || "Unknown",
              id: inv.processId || "Unknown",
              time: inv.processStartTime || "Unknown"
            }],
            totalInput: 0,
            totalOutput: 0,
            difference: 0,
            chain: chainKey
          };
        } else {
          const existingProcess = processChainMap[chainKey].processCodes.find(
            (proc) => proc.id === inv.processId
          );
          if (!existingProcess) {
            processChainMap[chainKey].processCodes.push({
              code: inv.processCode || "Unknown",
              id: inv.processId || "Unknown",
              time: inv.processStartTime || "Unknown"
            });
          }
        }
        let matchingProduct = null;
        this.inputProducts.forEach((group) => {
          group.products.forEach((prod) => {
            if (
              (this.reportParams.inputProductIds.length === 0 ||
               this.reportParams.inputProductIds.includes(prod.id)) &&
              prod.name === inv.inventoryName
            ) {
              matchingProduct = prod;
            }
          });
        });
        if (matchingProduct) {
          const key = `input_${matchingProduct.id}`;
          processChainMap[chainKey][key] =
            (processChainMap[chainKey][key] || 0) + (inv.weight || 0);
        }
        processChainMap[chainKey].totalInput += inv.weight || 0;
      });

      // Process output inventories
      this.reportData.outputInventories.forEach((inv) => {
        const chainKey = inv.processChain ? inv.processChain : inv.processId;
        if (!processChainMap[chainKey]) {
          processChainMap[chainKey] = {
            processCodes: [{
              code: inv.processCode || "Unknown",
              id: inv.processId || "Unknown",
              time: inv.processStartTime || "Unknown"
            }],
            totalInput: 0,
            totalOutput: 0,
            difference: 0,
            chain: chainKey
          };
        } else {
          const existingProcess = processChainMap[chainKey].processCodes.find(
            (proc) => proc.id === inv.processId
          );
          if (!existingProcess) {
            processChainMap[chainKey].processCodes.push({
              code: inv.processCode || "Unknown",
              id: inv.processId || "Unknown",
              time: inv.processStartTime || "Unknown"
            });
          }
        }
        let matchingProduct = null;
        this.outputProducts.forEach((group) => {
          group.products.forEach((prod) => {
            if (
              (this.reportParams.outputProductIds.length === 0 ||
               this.reportParams.outputProductIds.includes(prod.id)) &&
              prod.name === inv.inventoryName
            ) {
              matchingProduct = prod;
            }
          });
        });
        if (matchingProduct) {
          const key = `output_${matchingProduct.id}`;
          processChainMap[chainKey][key] =
            (processChainMap[chainKey][key] || 0) + (inv.weight || 0);
        }
        processChainMap[chainKey].totalOutput += inv.weight || 0;
      });

      Object.keys(processChainMap).forEach((chainKey) => {
        const row = processChainMap[chainKey];
        row.difference = (row.totalInput || 0) - (row.totalOutput || 0);
      });

      return Object.values(processChainMap);
    },
    overallFinalReportItems() {
      return this.finalReportItems;
    },
    totalRow() {
      const totals = {};
      this.finalReportHeaders.forEach((header) => {
        if (header.value !== "processCodes" && header.value !== "processStartTime") {
          totals[header.value] = 0;
        }
      });
      this.finalReportItems.forEach((item) => {
        this.finalReportHeaders.forEach((header) => {
          if (header.value !== "processCodes" && header.value !== "processStartTime") {
            const value = parseFloat(item[header.value]) || 0;
            totals[header.value] += value;
          }
        });
      });
      Object.keys(totals).forEach((key) => {
        totals[key] = totals[key].toFixed(2);
      });
      return totals;
    },
    combinedRawInventories() {
      if (!this.reportData) return [];
      const input = this.reportData.inputInventories.map(item => ({
        ...item,
        inventoryType: "Input",
        processChain: item.processChain ? item.processChain : item.processId
      }));
      const output = this.reportData.outputInventories.map(item => ({
        ...item,
        inventoryType: "Output",
        processChain: item.processChain ? item.processChain : item.processId
      }));
      return [...input, ...output];
    },
    overallCombinedRawInventories() {
      return this.combinedRawInventories;
    },
    rawCombinedHeaders() {
      return [
        { text: "Inventory Type", value: "inventoryType" },
        { text: "Inventory ID", value: "inventoryId" },
        { text: "Inventory Name", value: "inventoryName" },
        { text: "Weight", value: "weight" },
        { text: "Process ID", value: "processId" },
        { text: "Process Code", value: "processCode" },
        { text: "Process Type ID", value: "processTypeId" },
        { text: "Process Start Time", value: "processStartTime" },
        { text: "Process End Time", value: "processEndTime" },
        { text: "Process Chain", value: "processChain" },
        { text: "Product Group", value: "productGroup" }
      ];
    },
    filteredFinalReportItems() {
      return this.finalReportItems.filter(row => {
        let inputPass = true;
        let outputPass = true;
        if (
          this.reportFilter.selectedInputProductIds &&
          this.reportFilter.selectedInputProductIds.length > 0
        ) {
          inputPass = this.reportFilter.selectedInputProductIds.every(prodId => {
            const key = `input_${prodId}`;
            const threshold = this.reportFilter.inputThresholds[prodId] || 0;
            return row[key] && Number(row[key]) >= threshold;
          });
        }
        if (
          this.reportFilter.selectedOutputProductIds &&
          this.reportFilter.selectedOutputProductIds.length > 0
        ) {
          outputPass = this.reportFilter.selectedOutputProductIds.every(prodId => {
            const key = `output_${prodId}`;
            const threshold = this.reportFilter.outputThresholds[prodId] || 0;
            return row[key] && Number(row[key]) >= threshold;
          });
        }
        return inputPass && outputPass;
      });
    },
    isFilterActive() {
      return (
        (this.reportFilter.selectedInputProductIds && this.reportFilter.selectedInputProductIds.length > 0) ||
        (this.reportFilter.selectedOutputProductIds && this.reportFilter.selectedOutputProductIds.length > 0) ||
        Object.values(this.reportFilter.inputThresholds).some(v => v > 0) ||
        Object.values(this.reportFilter.outputThresholds).some(v => v > 0)
      );
    },
    displayedTotalRow() {
      const totals = {};
      this.finalReportHeaders.forEach(header => {
        if (header.value !== "processCodes" && header.value !== "processStartTime") {
          totals[header.value] = 0;
        }
      });
      this.filteredFinalReportItems.forEach(item => {
        this.finalReportHeaders.forEach(header => {
          if (header.value !== "processCodes" && header.value !== "processStartTime") {
            const value = parseFloat(item[header.value]) || 0;
            totals[header.value] += value;
          }
        });
      });
      Object.keys(totals).forEach(key => {
        totals[key] = totals[key].toFixed(2);
      });
      return totals;
    }
  },
  methods: {
    ...mapActions("processes", [
      "loadProcessTypes",
      "loadLinkedProcessTypes",
      "loadInputProducts",
      "loadOutputProducts",
      "loadProcesses"
    ]),
    async handleParametersSelected(payload) {
      const { reportParams, selectedProcessTypes } = payload;
      this.reportParams = reportParams;
      this.reportParams.processTypeIds = selectedProcessTypes.map((type) => type.id);
      await this.fetchReport();
      this.showParameters = false;
    },
    async fetchReport() {
      this.loadingReport = true;
      this.reportData = null;
      this.subgraphs = [];  // clear any old data
      try {
        const payload = {
          processIds: this.reportParams.processIds,
          processTypeIds: this.reportParams.processTypeIds,
          inputProductIds: this.reportParams.inputProductIds,
          outputProductIds: this.reportParams.outputProductIds,
          includeSubgraph: true,
        };
        const response = await this.$axios.post("/api/conversion-reports/summary", payload);
        // 1) Store the summary
        this.reportData = response.data.summary;
        // 2) Store the subgraphs
        this.subgraphs = response.data.subgraphs || [];

        console.log("Received summary:", this.reportData);
        console.log("Received subgraphs:", this.subgraphs);
      } catch (error) {
        console.error("Error fetching summary:", error);
        this.showAlert("error", this.$vuetify.lang.t("$vuetify.processConversionReport.fetchError"));
      } finally {
        this.loadingReport = false;
      }
    },
    showAlert(type, message) {
      this.$root.$emit("show-snackbar", { type, message });
    },
    getFirstDayOfCurrentMonth() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), 1)
        .toISOString()
        .substr(0, 10);
    },
    getLastDayOfCurrentMonth() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth() + 1, 0)
        .toISOString()
        .substr(0, 10);
    },
    toggleParameters() {
      this.showParameters = !this.showParameters;
    },
    exportRawData() {
      if (!this.reportData) {
        this.showAlert("error", "No raw data available to export.");
        return;
      }
      const wb = XLSX.utils.book_new();
      const combinedData = this.combinedRawInventories.map((item) => ({
        "Inventory Type": item.inventoryType || "",
        "Inventory ID": item.inventoryId || "",
        "Inventory Name": item.inventoryName || "",
        "Weight": item.weight || 0,
        "Process ID": item.processId || "",
        "Process Code": item.processCode || "",
        "Process Type ID": item.processTypeId || "",
        "Process Start Time": item.processStartTime || "",
        "Process End Time": item.processEndTime || "",
        "Process Chain": item.processChain || "",
        "Product Group": item.productGroup || ""
      }));
      const ws = XLSX.utils.json_to_sheet(combinedData);
      XLSX.utils.book_append_sheet(wb, ws, "Raw Inventories");
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      saveAs(new Blob([wbout], { type: "application/octet-stream" }), "raw_data.xlsx");
    },
    exportAggregatedReport() {
      if (!this.reportData) {
        this.showAlert("error", "No aggregated report data available to export.");
        return;
      }
      
      const aggregatedData = this.finalReportItems;
      // Filter exported data to only include displayed columns
      const selectedColumns = this.finalReportHeaders.map(h => h.value);

      // Create a mapping object from our finalReportHeaders
      const columnMapping = {};
      this.finalReportHeaders.forEach(header => {
        columnMapping[header.value] = header.text;
      });

      // Build a new data array that uses the friendly column names as keys
      const filteredData = aggregatedData.map(row => {
        const newRow = {};
        selectedColumns.forEach(col => {
          if (col === 'processCodes') {
            // Convert array of process codes
            newRow[columnMapping[col]] = row[col]
              .map(proc => `${proc.time} - ${proc.code ? proc.code : 'N/A'} (ID: ${proc.id})`)
              .join('\n');
          } else {
            newRow[columnMapping[col]] = row[col];
          }
        });
        return newRow;
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(filteredData);
      XLSX.utils.book_append_sheet(wb, ws, "Aggregated Report");
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      saveAs(new Blob([wbout], { type: "application/octet-stream" }), "aggregated_report.xlsx");
    },
    resetFilters() {
      this.reportFilter.selectedInputProductIds = [];
      this.reportFilter.inputThresholds = {};
      this.reportFilter.selectedOutputProductIds = [];
      this.reportFilter.outputThresholds = {};
    },
    resetColumnSelection() {
      this.columnFilter.selectedInputColumns = this.flatInputProducts.map(p => p.id);
      this.columnFilter.selectedOutputColumns = this.flatOutputProducts.map(p => p.id);
    },
    getInputProductName(id) {
      const prod = this.flatInputProducts.find(p => p.id === id);
      return prod ? prod.name : id;
    },
    getOutputProductName(id) {
      const prod = this.flatOutputProducts.find(p => p.id === id);
      return prod ? prod.name : id;
    },
    async fetchAvailableProcesses() {
      this.isProcessesLoading = true;
      try {
        const response = await this.loadProcesses({
          startDate: this.params.startDate,
          endDate: this.params.endDate,
          processTypeIds: this.selectedProcessTypes
            .filter(pt => pt && pt.id)
            .map(pt => pt.id),
          inputProductIds: this.params.inputProductIds,
          outputProductIds: this.params.outputProductIds
        });
        const processes = Array.isArray(response) ? response : response.data;
        this.availableProcesses = processes.map(process => ({
          id: process.id,
          processCode: process.processCode,
          processStartTime: process.processStartTime,
          processEndTime: process.processEndTime,
          processTypeName: process.processTypeName,
          status: process.status,
          matchesInput: process.matchesInput,
          matchesOutput: process.matchesOutput
        }));
        this.selectedProcesses = this.availableProcesses.filter(
          process => process.status === "COMPLETED"
        );
      } catch (error) {
        this.showAlert(
          "error",
          this.$vuetify.lang.t("$vuetify.processConversionReport.fetchError")
        );
        console.error("Error fetching processes:", error);
      } finally {
        this.isProcessesLoading = false;
      }
    },

    // Methods to handle the subgraph dialog
    openSubgraphDialog(chainId) {
      const index = chainId - 1;
      if (index >= 0 && index < this.subgraphs.length) {
        this.subgraphDialog.chainId = chainId;
        this.subgraphDialog.subgraph = this.subgraphs[index];
        this.subgraphDialog.visible = true;
      } else {
        console.warn("No matching subgraph for chainId:", chainId);
      }
    },
    closeSubgraphDialog() {
      this.subgraphDialog.visible = false;
      this.subgraphDialog.subgraph = null;
      this.subgraphDialog.chainId = null;
    }
  },
  watch: {
    selectedProcesses(newVal) {
      this.processValid = true;
    },
    flatInputProducts(newVal) {
      if (!this.columnFilter.selectedInputColumns.length && newVal.length) {
        this.columnFilter.selectedInputColumns = newVal.map(p => p.id);
      }
    },
    flatOutputProducts(newVal) {
      if (!this.columnFilter.selectedOutputColumns.length && newVal.length) {
        this.columnFilter.selectedOutputColumns = newVal.map(p => p.id);
      }
    }
  }
};
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>
