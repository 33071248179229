<template>
  <v-stepper v-model="currentStep" vertical>
    <!-- Step 1: Choose Date Range -->
    <v-stepper-step
      step="1"
      :complete="currentStep > 1"
      :editable="currentStep > 1"
      @click="goToPrevStep(1)"
    >
      {{ $vuetify.lang.t("$vuetify.processConversionReport.step1") }}
    </v-stepper-step>
    <v-stepper-content step="1">
      <v-form
        ref="dateForm"
        v-model="dateValid"
        @submit.prevent="goToNextStep(2)"
      >
        <v-row>
          <!-- Start Date Picker -->
          <v-col cols="12" sm="6">
            <v-menu
              v-model="startMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="params.startDate"
                  :label="$vuetify.lang.t('$vuetify.base.startDate')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="params.startDate"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- End Date Picker -->
          <v-col cols="12" sm="6">
            <v-menu
              v-model="endMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="params.endDate"
                  :label="$vuetify.lang.t('$vuetify.base.endDate')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required, rules.endAfterStart]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="params.endDate"
                @input="endMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <!-- No back button on first step since there's nowhere to go back -->
            <v-btn
              :disabled="!dateValid"
              color="primary"
              @click="goToNextStep(2)"
            >
              {{ $vuetify.lang.t("$vuetify.base.next") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-stepper-content>

    <!-- Step 2: Choose Process Types -->
    <v-stepper-step
      step="2"
      :complete="currentStep > 2"
      :editable="currentStep > 1"
      @click="goToPrevStep(2)"
    >
      {{ $vuetify.lang.t("$vuetify.processConversionReport.step2") }}
    </v-stepper-step>
    <v-stepper-content step="2">
      <v-form
        ref="processForm"
        v-model="processValid"
        @submit.prevent="goToNextStep(3)"
      >
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                v-for="(processType, index) in selectedProcessTypes"
                :key="processType ? processType.id : `placeholder-${index}`"
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <v-select
                  v-model="selectedProcessTypes[index]"
                  :items="getProcessTypesForIndex(index)"
                  item-text="name"
                  item-value="id"
                  :label="`${$vuetify.lang.t('$vuetify.processConversionReport.processTypes')} ${index + 1}`"
                  :rules="[rules.required]"
                  @change="handleProcessTypeChange(index, $event)"
                  return-object
                ></v-select>

                <!-- Arrow Icon Between Selectors (if not last) -->
                <v-icon
                  v-if="index < selectedProcessTypes.length - 1"
                  class="ml-2"
                >
                  mdi-arrow-right
                </v-icon>

                <!-- Delete Icon on the Right -->
                <v-btn
                  icon
                  color="red"
                  @click="removeProcessTypeSelection(index)"
                  class="ml-2"
                  :disabled="selectedProcessTypes.length === 1"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <!-- Add Process Type Button -->
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn
                  color="primary"
                  @click="addProcessType"
                  :disabled="!canAddProcessType"
                  class="mt-2"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $vuetify.lang.t("$vuetify.processConversionReport.addProcessType") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn text @click="goToPrevStep(1)">
              {{ $vuetify.lang.t("$vuetify.base.back") }}
            </v-btn>
            <v-btn
              :disabled="!processValid"
              color="primary"
              @click="goToNextStep(3)"
            >
              {{ $vuetify.lang.t("$vuetify.base.next") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-stepper-content>

    <!-- Step 3: Choose Input Products and Output Products -->
    <v-stepper-step
      step="3"
      :complete="currentStep > 3"
      :editable="currentStep > 2"
      @click="goToPrevStep(3)"
    >
      {{ $vuetify.lang.t("$vuetify.processConversionReport.step3") }}
    </v-stepper-step>
    <v-stepper-content step="3">
      <v-form
        ref="inputOutputForm"
        v-model="inputOutputValid"
        @submit.prevent="goToNextStep(4)"
      >
        <v-row>
          <!-- Input Product Selector -->
          <v-col cols="12" sm="6">
            <v-select
              v-model="params.inputProductIds"
              :items="groupedInputItems"
              item-text="name"
              item-value="id"
              :label="$vuetify.lang.t('$vuetify.processConversionReport.inputProducts')"
              multiple
              :loading="isInputProductsLoading"
              :rules="[rules.required]"
              chips
              deletable-chips
              clearable
            >
              <template v-slot:item="{ item }">
                <v-subheader v-if="item.header">{{ item.header }}</v-subheader>
                <div v-else>{{ item.name }}</div>
              </template>
            </v-select>
          </v-col>

          <!-- Output Product Selector -->
          <v-col cols="12" sm="6">
            <v-select
              v-model="params.outputProductIds"
              :items="groupedOutputItems"
              item-text="name"
              item-value="id"
              :label="$vuetify.lang.t('$vuetify.processConversionReport.outputProducts')"
              multiple
              :loading="isOutputProductsLoading"
              :rules="[rules.required]"
              chips
              deletable-chips
              clearable
            >
              <template v-slot:item="{ item }">
                <v-subheader v-if="item.header">{{ item.header }}</v-subheader>
                <div v-else>{{ item.name }}</div>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn text @click="goToPrevStep(2)">
              {{ $vuetify.lang.t("$vuetify.processConversionReport.back") }}
            </v-btn>
            <v-btn
              :disabled="!inputOutputValid"
              color="primary"
              @click="goToNextStep(4)"
            >
              {{ $vuetify.lang.t("$vuetify.base.next") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-stepper-content>

    <!-- Step 4: Filter by Processes (Optional) -->
    <v-stepper-step
      step="4"
      :complete="currentStep > 4"
      :editable="currentStep > 3"
      @click="goToPrevStep(4)"
    >
      {{ $vuetify.lang.t("$vuetify.processConversionReport.step4") }}
      ({{ $vuetify.lang.t("$vuetify.base.optional") }})
    </v-stepper-step>
    <v-stepper-content step="4">
      <v-form
        ref="processForm"
        v-model="processValid"
        @submit.prevent="emitReportParameters"
      >
        <!-- Process Status Filter -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="processStatusFilter"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :label="$vuetify.lang.t('$vuetify.processConversionReport.statusFilter')"
              clearable
            ></v-select>
          </v-col>
        </v-row>

        <!-- Process Selection Table -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="processTableHeaders"
              :items="filteredAvailableProcesses"
              :loading="isProcessesLoading"
              item-key="id"
              show-select
              v-model="selectedProcesses"
              class="elevation-1 process-table"
              :items-per-page="10"
              :footer-props="{
                'items-per-page-options': [5, 10, 15],
                'items-per-page-text': $vuetify.lang.t('$vuetify.dataTable.itemsPerPageText'),
                'show-current-page': true
              }"
              :search="processSearch"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.processConversionReport.processes') }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="processSearch"
                    append-icon="mdi-magnify"
                    :label="$vuetify.lang.t('$vuetify.base.search')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>

              <!-- Custom Slot for matchesInput Icon -->
              <template v-slot:item.matchesInput="{ item }">
                <v-icon :color="item.matchesInput ? 'green' : 'grey'">
                  {{ item.matchesInput ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}
                </v-icon>
              </template>

              <!-- Custom Slot for matchesOutput Icon -->
              <template v-slot:item.matchesOutput="{ item }">
                <v-icon :color="item.matchesOutput ? 'green' : 'grey'">
                  {{ item.matchesOutput ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}
                </v-icon>
              </template>

              <!-- No Data Slot -->
              <template v-slot:no-data>
                <v-alert :value="true" color="warning" icon="mdi-alert">
                  {{ $vuetify.lang.t('$vuetify.processConversionReport.noProcessesFound') }}
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn text @click="goToPrevStep(3)">
              {{ $vuetify.lang.t("$vuetify.base.back") }}
            </v-btn>
            <v-btn
              :disabled="!processValid"
              color="primary"
              type="submit"
            >
              <v-icon left>mdi-file-chart</v-icon>
              {{ $vuetify.lang.t("$vuetify.processConversionReport.generateReport") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ConversionReportParameters",
  props: {
    processTypes: {
      type: Array,
      required: true,
    },
    linkedProcessTypes: {
      type: Function,
      required: true,
    },
    inputProducts: {
      type: Array,
      required: true,
    },
    outputProducts: {
      type: Array,
      required: true,
    },
    isInputProductsLoading: {
      type: Boolean,
      default: false,
    },
    isOutputProductsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStep: 1,
      dateValid: false,
      processValid: false,
      inputOutputValid: false,
      startMenu: false,
      endMenu: false,
      processSearch: "",
      // New: Process status filter and options
      processStatusFilter: "COMPLETED",
      statusOptions: [
        { text: "All", value: "ALL" },
        { text: "Completed", value: "COMPLETED" },
        { text: "In Progress", value: "IN_PROGRESS" }
      ],
      params: {
        startDate: this.getFirstDayOfCurrentMonth(),
        endDate: this.getLastDayOfCurrentMonth(),
        inputProductIds: [],
        outputProductIds: [],
        processIds: [],
        processTypeIds: [],
      },
      selectedProcessTypes: [null],
      isProcessesLoading: false,
      availableProcesses: [],
      selectedProcesses: []
    };
  },
  computed: {
    ...mapGetters("processes", [
      "linkedProcessTypes",
      "inputProducts",
      "outputProducts",
      "fetchedProcesses"
    ]),
    rules() {
      return {
        required: (value) =>
          !!value || this.$vuetify.lang.t("$vuetify.validation.fieldRequired"),
        endAfterStart: (value) => {
          if (!value || !this.params.startDate) return true;
          return (
            new Date(value) >= new Date(this.params.startDate) ||
            this.$vuetify.lang.t("$vuetify.validation.endDateAfterStart")
          );
        },
        optional: () => true
      };
    },
    availableLinkedProcessTypes() {
      if (this.selectedProcessTypes.length === 0) {
        return this.processTypes || [];
      } else {
        const lastSelected =
          this.selectedProcessTypes[this.selectedProcessTypes.length - 1];
        if (!lastSelected || !lastSelected.id) {
          return [];
        }
        const processTypeId = lastSelected.id;
        const linked = this.linkedProcessTypes(processTypeId) || [];
        return linked.filter(
          (pt) =>
            !this.selectedProcessTypes.some(
              (selected) => selected && selected.id === pt.id
            )
        );
      }
    },
    canAddProcessType() {
      if (this.selectedProcessTypes.length === 0) {
        return this.processTypes && this.processTypes.length > 0;
      }
      const lastSelected =
        this.selectedProcessTypes[this.selectedProcessTypes.length - 1];
      if (!lastSelected || !lastSelected.id) {
        return false;
      }
      const available = this.linkedProcessTypes(lastSelected.id) || [];
      return (
        available.filter(
          (pt) =>
            !this.selectedProcessTypes.some(
              (selected) => selected && selected.id === pt.id
            )
        ).length > 0
      );
    },
    groupedInputItems() {
      return this.inputProducts.flatMap(group => [
        { header: group.processTypeName },
        ...group.products
      ]);
    },
    groupedOutputItems() {
      return this.outputProducts.flatMap(group => [
        { header: group.processTypeName },
        ...group.products
      ]);
    },
    processTableHeaders() {
      return [
        { text: this.$vuetify.lang.t("$vuetify.processConversionReport.processCode"), value: "processCode" },
        { text: this.$vuetify.lang.t("$vuetify.base.startDate"), value: "processStartTime" },
        { text: this.$vuetify.lang.t("$vuetify.base.endDate"), value: "processEndTime" },
        { text: this.$vuetify.lang.t("$vuetify.processConversionReport.processTypes"), value: "processTypeName" },
        { text: this.$vuetify.lang.t("$vuetify.processConversionReport.status"), value: "status" },
        { text: this.$vuetify.lang.t("$vuetify.processConversionReport.matchesInput"), value: "matchesInput" },
        { text: this.$vuetify.lang.t("$vuetify.processConversionReport.matchesOutput"), value: "matchesOutput" }
      ];
    },
    // New computed property to filter available processes by status
    filteredAvailableProcesses() {
      if (this.processStatusFilter === "ALL") {
        return this.availableProcesses;
      }
      return this.availableProcesses.filter(
        process => process.status === this.processStatusFilter
      );
    }
  },
  methods: {
    ...mapActions("processes", [
      "loadProcessTypes",
      "loadLinkedProcessTypes",
      "loadInputProducts",
      "loadOutputProducts",
      "loadProcesses"
    ]),
    goToNextStep(step) {
      this.currentStep = step;
      if (step === 3) {
        this.loadInputProducts({
          startDate: this.params.startDate,
          endDate: this.params.endDate,
          processTypeIds: this.selectedProcessTypes
            .filter(pt => pt && pt.id)
            .map(pt => pt.id)
        });
        this.loadOutputProducts({
          startDate: this.params.startDate,
          endDate: this.params.endDate,
          processTypeIds: this.selectedProcessTypes
            .filter(pt => pt && pt.id)
            .map(pt => pt.id)
        });
      }
      if (step === 4) {
        this.fetchAvailableProcesses();
      }
    },
    goToPrevStep(step) {
      if (step < this.currentStep) {
        this.currentStep = step;
      }
    },
    getProcessTypesForIndex(index) {
      if (index === 0) {
        return this.processTypes || [];
      } else {
        const previousProcessType = this.selectedProcessTypes[index - 1];
        if (previousProcessType && previousProcessType.id) {
          return (
            this.linkedProcessTypes(previousProcessType.id).filter((pt) => {
              const isSelectedElsewhere = this.selectedProcessTypes.some(
                (selected, idx) =>
                  selected && selected.id === pt.id && idx !== index
              );
              return !isSelectedElsewhere;
            }) || []
          );
        }
        return [];
      }
    },
    async handleProcessTypeChange(index, selectedProcessType) {
      if (!selectedProcessType) return;
      if (this.selectedProcessTypes.length > index + 1) {
        this.selectedProcessTypes.splice(index + 1);
      }
      const { startDate, endDate } = this.params;
      await this.loadLinkedProcessTypes({
        processTypeId: selectedProcessType.id,
        startDate,
        endDate
      });
    },
    async addProcessType() {
      if (!this.canAddProcessType) return;
      this.selectedProcessTypes.push(null);
    },
    removeProcessTypeSelection(index) {
      this.selectedProcessTypes.splice(index);
    },
    emitReportParameters() {
      // If no input products selected, treat it as all input products selected.
      if (!this.params.inputProductIds || this.params.inputProductIds.length === 0) {
        const allInputIds = [];
        this.inputProducts.forEach(group => {
          group.products.forEach(prod => {
            allInputIds.push(prod.id);
          });
        });
        this.params.inputProductIds = allInputIds;
      }
      // If no output products selected, treat it as all output products selected.
      if (!this.params.outputProductIds || this.params.outputProductIds.length === 0) {
        const allOutputIds = [];
        this.outputProducts.forEach(group => {
          group.products.forEach(prod => {
            allOutputIds.push(prod.id);
          });
        });
        this.params.outputProductIds = allOutputIds;
      }
      this.params.processIds = this.selectedProcesses.map(p => p.id);

      this.$emit("parametersSelected", {
        reportParams: this.params,
        selectedProcessTypes: this.selectedProcessTypes.filter(
          (pt) => pt && pt.id
        )
      });
    },
    showAlert(type, message) {
      this.$root.$emit("show-snackbar", { type, message });
    },
    getFirstDayOfCurrentMonth() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), 1)
        .toISOString()
        .substr(0, 10);
    },
    getLastDayOfCurrentMonth() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth() + 1, 0)
        .toISOString()
        .substr(0, 10);
    },
    async fetchAvailableProcesses() {
      this.isProcessesLoading = true;
      try {
        const response = await this.loadProcesses({
          startDate: this.params.startDate,
          endDate: this.params.endDate,
          processTypeIds: this.selectedProcessTypes
            .filter(pt => pt && pt.id)
            .map(pt => pt.id),
          inputProductIds: this.params.inputProductIds,
          outputProductIds: this.params.outputProductIds
        });
        const processes = Array.isArray(response) ? response : response.data;
        this.availableProcesses = processes.map(process => ({
          id: process.id,
          processCode: process.processCode,
          processStartTime: process.processStartTime,
          processEndTime: process.processEndTime,
          processTypeName: process.processTypeName,
          status: process.status,
          matchesInput: process.matchesInput,
          matchesOutput: process.matchesOutput
        }));
        // Default selection: only processes with status 'COMPLETED'
        this.selectedProcesses = this.availableProcesses.filter(
          process => process.status === "COMPLETED"
        );
      } catch (error) {
        this.showAlert(
          "error",
          this.$vuetify.lang.t("$vuetify.processConversionReport.fetchError")
        );
        console.error("Error fetching processes:", error);
      } finally {
        this.isProcessesLoading = false;
      }
    }
  },
  watch: {
    selectedProcesses(newVal) {
      // Since process selection is optional, always set processValid to true
      this.processValid = true;
    }
  },
  mounted() {
    this.loadProcessTypes();
  }
};
</script>

<style scoped>
.headline {
  font-weight: bold;
}
.d-flex {
  display: flex;
  align-items: center;
}
.ml-2 {
  margin-left: 8px;
}
.mt-2 {
  margin-top: 8px;
}
.process-table {
  max-height: 400px;
  overflow-y: auto;
}
.v-data-table-header {
  background-color: #f5f5f5;
}
</style>
